import * as React from "react";
import Table from "@cloudscape-design/components/table";
import Box from "@cloudscape-design/components/box";
import Button from "@cloudscape-design/components/button";
import TextFilter from "@cloudscape-design/components/text-filter";
import Header from "@cloudscape-design/components/header";
import Pagination from "@cloudscape-design/components/pagination";
import CollectionPreferences from "@cloudscape-design/components/collection-preferences";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Link from "@cloudscape-design/components/link";
import Cards from "@cloudscape-design/components/cards";
import SideNavigation from "@cloudscape-design/components/side-navigation";
import Badge from "@cloudscape-design/components/badge";
import TopNavigation from "@cloudscape-design/components/top-navigation";
import AppLayout from "@cloudscape-design/components/app-layout";
import Flashbar from "@cloudscape-design/components/flashbar";

import { useCollection } from '@cloudscape-design/collection-hooks';

export default () => {
  const [activeHref, setActiveHref] = React.useState(
    ""
  );
  
  const [
    selectedItems,
    setSelectedItems
  ] = React.useState([{ name: "Item 2", alt: "", description: "", status: "" }]);
  
  const [
    currentPageIndex,
    setCurrentPageIndex
  ] = React.useState(1);

  const [jobsCol, setJobsCol] = React.useState([
    {
      name: "Item 1",
      alt: "First",
      description: "This is the first item",
      status: "Created"
    },
    {
      name: "Item 2",
      alt: "Second",
      description: "This is the second item",
      status: "Created"
    },
    {
      name: "Item 3",
      alt: "Third",
      description: "This is the third item",
      status: "Created"
    },
    {
      name: "Item 4",
      alt: "Fourth",
      description: "This is the fourth item",
      status: "In Progress"
    },
    {
      name: "Item 5",
      alt: "Fifth",
      description: "This is the fifth item",
      status: "Completed"
    },
    {
      name: "Item 6",
      alt: "Sixth",
      description: "This is the sixth item",
      status: "Completed"
    },
    {
      name: "Item 7",
      alt: "Seventh",
      description: "This is the seventh item",
      status: "Completed"
    },
    {
      name: "Item 8",
      alt: "Eighth",
      description: "This is the eighth item",
      status: "Completed"
    }
  ]);

  const EmptyState = () => {
    return (
      <Box textAlign="center" color="inherit">
              <b>No resources</b>
              <Box
                padding={{ bottom: "s" }}
                variant="p"
                color="inherit"
              >
                No resources to display.
              </Box>
              <Button>Create resource</Button>
            </Box>
    )
  }

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    jobsCol,
    {
      filtering: {
        empty: (
          <EmptyState></EmptyState>
        ),
        noMatch: (
          <EmptyState />
        ),
      },
      pagination: { pageSize: 6 },
      sorting: {},
      selection: {},
    }
  );

  // interface itemTypes {
  //   Info?: string;
  // }

  // const itemType: itemTypes = "info";

  // const [items, setItems] = React.useState([
  //   {
  //     type: itemType,
  //     header: "Success!",
  //     dismissible: true,
  //     dismissLabel: "Dismiss message",
  //     onDismiss: () => setItems([]),
  //     content: (
  //       <>
  //         Your Job has been created successfully! Please see below for active Job Status.
  //       </>
  //     ),
  //     id: "message_1"
  //   }
  // ]);

  const Content = () => { 
    // setItems([{
    //   type: itemType,
    //   header: "Success!",
    //   dismissible: true,
    //   dismissLabel: "Dismiss message",
    //   onDismiss: () => setItems([]),
    //   content: (
    //     <>
    //       Your Job has been created successfully! Please see below for active Job Status.
    //     </>
    //   ),
    //   id: "message_1"
    // }])

    return (
      <SpaceBetween size="m">
        <Header
          counter="(3)"
          actions={
            <SpaceBetween direction="horizontal" size="s">
              <Button>Secondary button</Button>
              <Button variant="primary">
                Primary button
              </Button>
            </SpaceBetween>
          }
          info={<Link variant="info">Info</Link>}
        >
          Active Media Jobs
        </Header>
        {/* <Flashbar items={items} /> */}
        <Cards
          onSelectionChange={({ detail }) =>
            setSelectedItems(detail.selectedItems)
          }
          selectedItems={selectedItems}
          ariaLabels={{
            itemSelectionLabel: (e, t) => `select ${t.name}`,
            selectionGroupLabel: "Item selection"
          }}
          cardDefinition={{
            header: e => e.name,
            sections: [
              {
                id: "description",
                header: "Job Description",
                content: e => e.description
              },
              {
                id: "status",
                header: "Job Status",
                content: e => e.status
              }
            ]
          }}
          cardsPerRow={[
            { cards: 2 },
            { minWidth: 500, cards: 2 }
          ]}
          items={items}
          loadingText="Loading resources"
          selectionType="single"
          trackBy="name"
          visibleSections={["description", "status"]}
          empty={
            <Box textAlign="center" color="inherit">
              <b>No resources</b>
              <Box
                padding={{ bottom: "s" }}
                variant="p"
                color="inherit"
              >
                No resources to display.
              </Box>
              <Button>Create resource</Button>
            </Box>
          }
          filter={
            <TextFilter
              {...filterProps}
              // countText={getMatchesCountText(filteredItemsCount)}
              filteringAriaLabel="Filter instances"
            />
          }
          header={
            <Header
              counter={
                selectedItems.length
                  ? "(" + selectedItems.length + "/10)"
                  : "(10)"
              }
            >
              Search Active Jobs
            </Header>
          }
          pagination={<Pagination {...paginationProps} />}
          preferences={
            <CollectionPreferences
              title="Preferences"
              confirmLabel="Confirm"
              cancelLabel="Cancel"
              preferences={{
                pageSize: 6,
                visibleContent: [
                  "description",
                  "type",
                  "size"
                ]
              }}
              pageSizePreference={{
                title: "Select page size",
                options: [
                  { value: 6, label: "6 resources" },
                  { value: 12, label: "12 resources" }
                ]
              }}
              visibleContentPreference={{
                title: "Select visible content",
                options: [
                  {
                    label: "Main distribution properties",
                    options: [
                      {
                        id: "description",
                        label: "Description"
                      },
                      { id: "type", label: "Type" },
                      { id: "size", label: "Size" }
                    ]
                  }
                ]
              }}
            />
          }
        />
    </SpaceBetween>)
  }

  const SideNav = () => {
    return (

      <SideNavigation
        activeHref={activeHref}
        header={{ href: "#/", text: "Job Actions" }}
        onFollow={event => {
          if (!event.detail.external) {
            event.preventDefault();
            setActiveHref(event.detail.href);
          }
        }}
        items={[
          { type: "link", text: "Create New Job", href: "" },
          { type: "link", text: "Active Media Jobs", href: "#/page2" },
          { type: "link", text: "Media Job Archive", href: "#/page3" },
          { type: "divider" },
          {
            type: "link",
            text: "Notifications",
            href: "#/notifications",
            info: <Badge color="red">23</Badge>
          },
          // {
          //   type: "link",
          //   text: "Documentation",
          //   href: "https://example.com",
          //   external: true
          // }
        ]}
      />
      
    )
  }

  return (
    <div>
      
      <TopNavigation
        identity={{
          href: "#",
          title: "Service",
          logo: {
            src:
              "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNDNweCIgaGVpZ2h0PSIzMXB4IiB2aWV3Qm94PSIwIDAgNDMgMzEiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxnIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxyZWN0IGZpbGw9IiMyMzJmM2UiIHN0cm9rZT0iI2Q1ZGJkYiIgeD0iMC41IiB5PSIwLjUiIHdpZHRoPSI0MiIgaGVpZ2h0PSIzMCIgcng9IjIiPjwvcmVjdD4KICAgICAgICA8dGV4dCBmb250LWZhbWlseT0iQW1hem9uRW1iZXItUmVndWxhciwgQW1hem9uIEVtYmVyIiBmb250LXNpemU9IjEyIiBmaWxsPSIjRkZGRkZGIj4KICAgICAgICAgICAgPHRzcGFuIHg9IjkiIHk9IjE5Ij5Mb2dvPC90c3Bhbj4KICAgICAgICA8L3RleHQ+CiAgICA8L2c+Cjwvc3ZnPgo=",
            alt: "Service"
          }
        }}
        utilities={[
          {
            type: "button",
            text: "Link",
            href: "https://example.com/",
            external: true,
            externalIconAriaLabel: " (opens in a new tab)"
          },
          {
            type: "button",
            iconName: "notification",
            title: "Notifications",
            ariaLabel: "Notifications (unread)",
            badge: true,
            disableUtilityCollapse: false
          },
          {
            type: "menu-dropdown",
            iconName: "settings",
            ariaLabel: "Settings",
            title: "Settings",
            items: [
              {
                id: "settings-org",
                text: "Organizational settings"
              },
              {
                id: "settings-project",
                text: "Project settings"
              }
            ]
          },
          {
            type: "menu-dropdown",
            text: "Customer Name",
            description: "email@example.com",
            iconName: "user-profile",
            items: [
              { id: "profile", text: "Profile" },
              { id: "preferences", text: "Preferences" },
              { id: "security", text: "Security" },
              {
                id: "support-group",
                text: "Support",
                items: [
                  {
                    id: "documentation",
                    text: "Documentation",
                    href: "#",
                    external: true,
                    externalIconAriaLabel:
                      " (opens in new tab)"
                  },
                  { id: "support", text: "Support" },
                  {
                    id: "feedback",
                    text: "Feedback",
                    href: "#",
                    external: true,
                    externalIconAriaLabel:
                      " (opens in new tab)"
                  }
                ]
              },
              { id: "signout", text: "Sign out" }
            ]
          }
        ]}
        i18nStrings={{
          searchIconAriaLabel: "Search",
          searchDismissIconAriaLabel: "Close search",
          overflowMenuTriggerText: "More",
          overflowMenuTitleText: "All",
          overflowMenuBackIconAriaLabel: "Back",
          overflowMenuDismissIconAriaLabel: "Close menu"
        }}
      />
      <AppLayout
        headerSelector="#h"
        footerSelector="#f"
        content={<Content></Content>}
        navigation={<SideNav></SideNav>}
        />
    </div>
  );
}

function getMatchesCountText(filteredItemsCount: number | undefined): string | undefined {
  return `Found ${filteredItemsCount} Items...`;
}
